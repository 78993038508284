var map = null;
var markers = new Array();
var language = null;

initMap = function () {
  // The location of Uluru
  var uluru = { lat: -25.344, lng: 131.036 };
  // The map, centered at Uluru
  map = new google.maps.Map(document.getElementById("map"), {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    zoom: 4,
    center: uluru,
  });
  // The marker, positioned at Uluru
  // var marker = new google.maps.Marker({position: uluru, map: map});
};

printCompanies = function (cb) {
  var bounds = new google.maps.LatLngBounds();

  for (var i = 0; i < companies.length; i++) {
    var myLatLng = {
      lat: companies[i].location.location.lat,
      lng: companies[i].location.location.lng,
    };

    bounds.extend(myLatLng);

    markers[companies[i].A] = new google.maps.Marker({
      position: myLatLng,
      map: map,
      title: companies[i].B,
      icon: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
    });

    markers[companies[i].A].addListener(
      "click",
      (function (companyId) {
        return function () {
          // for (var i = 0; i < markers.length; i++) {
          //   markers[i].setIcon(
          //     "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          //   );
          // }

          markers.map((marker) =>
            marker.setIcon(
              "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
            )
          );

          markers[companyId].setIcon(
            "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          );

          $(".section-5-carousel").slick("slickGoTo", companyId - 1);

          $(".company-selected").removeClass("company-selected");
          $(".company_" + companyId).addClass("company-selected");
        };
      })(companies[i].A)
    );

    $(".section-5-carousel").append(
      "<div class='company-container company_" +
        companies[i].A +
        "' data-key='" +
        companies[i].A +
        "' data-lat='" +
        companies[i].location.location.lat +
        "' data-lng='" +
        companies[i].location.location.lng +
        "'>" +
        "<div class='company-triangle-box'><div class='company-triangle'></div></div>" +
        "<div class='company-logo-box'>" +
        "<img src='" +
        companies[i].image +
        "'/>" +
        "</div>" +
        "<div class='company-info-box'>" +
        "-" +
        "<h6>" +
        companies[i].B +
        "</h6>" +
        "<p>&#183; " +
        companies[i].D +
        "</p>" +
        "<p>&#183; " +
        companies[i].C +
        "</p>" +
        "<p>&#183; " +
        companies[i].E +
        "</p>" +
        "<p>&#183; " +
        companies[i].F +
        "</p>" +
        "<button data-key='" +
        i +
        "' class='read-more-companies'>Saber más</button>" +
        "</div>" +
        "<div>"
    );

    map.fitBounds(bounds);
  }

  cb();
};

setLanguage = function (lang) {
  language = lang;

  //HEADER
  $(".header-box-right h2:first-child").html(languages[lang].common.good_land);
  $(".header-box-right h2:nth-child(2)").html(
    languages[lang].common.good_wheat
  );
  $(".header-box-right h2:nth-child(3)").html(
    languages[lang].common.good_pasta
  );

  //MAP SECTION
  $(".section-2-left .section-2-item:first-child .section-2-item-text p").html(
    languages[lang].common.special_wheat_1
  );
  $(".section-2-left .section-2-item:nth-child(3) .section-2-item-text p").html(
    languages[lang].common.special_wheat_2
  );
  $(".section-2-left .section-2-item:nth-child(5) .section-2-item-text p").html(
    languages[lang].common.special_wheat_3
  );

  //PASTA WORLD
  $(".section-3-bottom p").html(languages[lang].common.pasta_world_big);

  $(".section-4-square-top-left p").html(languages[lang].common.durum_wheat_1);
  $(".section-4-square-middle p").html(languages[lang].common.durum_wheat_2);
  $(".section-4-square-bottom-box").html(languages[lang].common.durum_wheat_3);

  // $(".section-4-bottom-title h2").html(languages[lang].common.with_love);
  $(".section-5-title h2").html(
    languages[lang].common.exporting_argentine_factories
  );
  // $(".section-4-background").css({
  //     "background-image": "url('" + languages[lang].common.with_love_image + "')"
  // });
  // $(".section-4-middle p strong").text(languages[lang].common.no_gmo);

  $(".section-2-header-box").html(languages[lang].common.durum_title);

  //TITLE
  $(".buenos-aires-modal h2").text(languages[lang].bsas.title);
  $(".cordoba-modal h2").text(languages[lang].cordoba.title);
  $(".sanluis-modal h2").text(languages[lang].sanluis.title);
  $(".tucuman-modal h2").text(languages[lang].tucuman.title);

  //BODY
  $(".buenos-aires-modal .modal-body p:first-child").html(
    "<strong>" +
      languages[lang].bsas.southeast +
      ": </strong>" +
      languages[lang].bsas.southeast_text
  );
  $(".buenos-aires-modal .modal-body p:nth-child(2)").html(
    "<strong>" +
      languages[lang].bsas.centersouth +
      ": </strong>" +
      languages[lang].bsas.centersouth_text
  );
  $(".buenos-aires-modal .modal-body p:nth-child(3)").html(
    "<strong>" +
      languages[lang].bsas.southwest +
      ": </strong>" +
      languages[lang].bsas.southwest_text
  );

  $(".cordoba-modal .modal-body p").text(languages[lang].cordoba.text);
  $(".sanluis-modal .sanluis-modal-body-text").text(
    languages[lang].sanluis.text
  );
  $(".tucuman-modal .modal-body p").text(languages[lang].tucuman.text);

  $(".buenos-aires-modal .varieties-subtitle").text(
    languages[lang].common.varieties
  );
  $(".cordoba-modal .varieties-subtitle").text(
    languages[lang].common.varieties
  );
  $(".sanluis-modal .varieties-subtitle").text(
    languages[lang].common.varieties
  );
  $(".tucuman-modal .varieties-subtitle").text(
    languages[lang].common.varieties
  );

  // if ($(".section-4-carousel").slick) $(".section-4-carousel").slick("unslick");

  $(".section-4-carousel .section-4-carousel-item.slick-1 img").attr(
    "src",
    languages[lang].second_banner.img1
  );
  $(".section-4-carousel .section-4-carousel-item.slick-2 img").attr(
    "src",
    languages[lang].second_banner.img2
  );
  $(".section-4-carousel .section-4-carousel-item.slick-3 img").attr(
    "src",
    languages[lang].second_banner.img3
  );

  //FOOTER
  $(
    ".buenos-aires-modal .modal-footer .modal-footer-image-box:first-child p"
  ).text(languages[lang].common.carilo);
  $(
    ".buenos-aires-modal .modal-footer .modal-footer-image-box:nth-child(2) p"
  ).text(languages[lang].common.quillen);
  $(
    ".buenos-aires-modal .modal-footer .modal-footer-image-box:nth-child(3) p"
  ).text(languages[lang].common.zafiro);

  $(".cordoba-modal .modal-footer .modal-footer-image-box:first-child p").text(
    languages[lang].common.carilo
  );
  $(".cordoba-modal .modal-footer .modal-footer-image-box:nth-child(2) p").text(
    languages[lang].common.quillen
  );
  $(".cordoba-modal .modal-footer .modal-footer-image-box:nth-child(3) p").text(
    languages[lang].common.zafiro
  );

  $(".sanluis-modal .sanluis-varieties").text(
    languages[lang].sanluis.varieties
  );

  $(".tucuman-modal .modal-footer .modal-footer-image-box:first-child p").text(
    languages[lang].common.facon
  );
  $(".tucuman-modal .modal-footer .modal-footer-image-box:nth-child(2) p").text(
    languages[lang].common.esmerald
  );

  //PASTIFICIOS
  $(".read-more-companies").text(languages[lang].common.know_more);

  $(".varieties-subtitle.products").text(languages[lang].common.products);
};

function getLang() {
  return language;
}

(function () {
  $("body").on("click", ".company-container", function () {
    var key = $(this).data("key");

    for (var i = 0; i < markers.length; i++) {
      markers[i].setIcon(
        "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
      );
    }

    markers[key].setIcon(
      "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
    );

    $(".company-selected").removeClass("company-selected");
    $(".company_" + key).addClass("company-selected");
  });

  $("body").on("click", ".read-more-companies", function () {
    var key = $(this).data("key");

    $(".company-modal .modal-body").empty();

    var lang = getLang();

    if (lang === "pt") lang = "en";

    for (var i = 0; i < companies[key][lang].texts.length; i++) {
      $(".company-modal .modal-body").append(
        "<p>" + companies[key][lang].texts[i] + "</p>"
      );
    }

    $(".company-modal h2").html(
      companies[key].B +
        "<span class='capacity_tag " +
        companies[key].capacity_class +
        "'>" +
        companies[key][lang].capacity +
        "</span>"
    );
    $(".company-modal .varieties-subtitle").text(companies[key][lang].grain);
    $(".company-modal .modal-footer").empty();

    for (var i = 0; i < companies[key].images.length; i++) {
      $(".company-modal .modal-footer").append(
        '<a data-lightbox="roadtrip" data-lightbox="' +
          companies[key].images[i] +
          '" class="company-images-lightbox" href="' +
          companies[key].images[i] +
          '"><div class="company-image" style="background-image: url(' +
          companies[key].images[i] +
          ')"></div></a>'
      );
    }

    $(".company-modal .modal-footer-products").empty();
    for (var i = 0; i < companies[key].products_images.length; i++) {
      $(".company-modal .modal-footer-products").append(
        '<a data-lightbox="roadtrip" data-lightbox="' +
          companies[key].products_images[i] +
          '" class="company-images-lightbox" href="' +
          companies[key].products_images[i] +
          '"><div class="product-image" style="background-image: url(' +
          companies[key].products_images[i] +
          ')"></div></a>'
      );
    }

    // $(".company-modal .tag").text(companies[key][lang].capacity);

    $(".modal.company-modal").addClass("show");
  });

  $(".locations-box div").on("click", function () {
    setLanguage($(this).data("lang"));
  });

  if (
    navigator.language.indexOf("es") !== -1 ||
    navigator.language.indexOf("ES") !== -1
  ) {
    setLanguage("es");
  } else if (
    navigator.language.indexOf("pt") !== -1 ||
    navigator.language.indexOf("PT") !== -1
  ) {
    setLanguage("pt");
  } else {
    setLanguage("en");
  }

  $(document).on("scroll", function () {
    if ($(document).scrollTop() >= 700) {
      $("#fixed-header").addClass("show");
    } else {
      $("#fixed-header").removeClass("show");
    }
  });

  $(".zone-activator").on("click", function () {
    $(".modal." + $(this).data("target") + "-modal").addClass("show");
  });

  $(".zone-activator").on("mouseenter", function () {
    $("." + $(this).data("target")).addClass("show");
  });

  $(".zone-activator").on("mouseleave", function () {
    $("." + $(this).data("target")).removeClass("show");
  });

  $(".section-4-carousel").slick({
    infinite: true,
    arrows: true,
    autoplay: true,
    nextArrow:
      '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fa fa-chevron-right"></i></button>',
    prevArrow:
      '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="fa fa-chevron-left"></i></button>',
  });

  setTimeout(() => {
    $("i.close").on("click", function () {
      $("." + $(this).data("target") + "-modal").removeClass("show");
    });
    printCompanies(function () {
      $(".section-5-carousel").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        nextArrow:
          '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fa fa-chevron-right"></i></button>',
        prevArrow:
          '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="fa fa-chevron-left"></i></button>',
        // autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
  }, 1000);
})();

var companies = [
  {
    A: 1,
    B: "MOLINOS TRES ARROYOS",
    C: "Tres Arroyos",
    D: "Buenos Aires",
    E: "(+54)02983-432489",
    F: "comex@molinostresarroyos.com.ar / juan@molinostsas.com.ar ",
    G: "www.molinostresarroyos.com.ar",
    H: "NO",
    I: "SI",
    J: "NO",
    K: "NO",
    L: "SI",
    M: "SI",
    image: "/img/molinos-tres-arroyos.jpg",
    images: [
      "/img/tresarroyos1.JPG",
      "/img/tresarroyos2.JPG",
      "/img/tresarroyos3.JPG",
    ],
    products_images: [
      "/img/tres-arroyos-producto-1.jpg",
      "/img/tres-arroyos-producto-2.jpg",
      "/img/tres-arroyos-producto-3.jpg",
      "/img/tres-arroyos-producto-4.jpg",
      "/img/tres-arroyos-producto-5.jpg",
      "/img/tres-arroyos-producto-6.jpg",
      "/img/tres-arroyos-producto-7.jpg",
      "/img/tres-arroyos-producto-8.jpg",
      "/img/tres-arroyos-producto-9.jpg",
    ],
    location: {
      location: {
        lat: -38.3664308,
        lng: -60.3199736,
      },
    },
    capacity_class: "high",
    es: {
      capacity: "Empresa de gran capacidad",
      grain: "ISO 22005 - FSC 22000",
      texts: [
        "Fideos de prensa cortos, largos y soperos , de harina o semola de trigo duro, en varios formatos.",
        "Grandes Supermercados o distribuidores / Marcas Blancas",
        "Operamos tecnología italiana de última generación.",
      ],
    },
    en: {
      capacity: "High capacity company",
      grain: "ISO 22005 - FSC 22000",
      texts: [
        "Noodles – short, long, or soup- variety Shapes, made of flour or Durum Wheat semolina ",
        "Large-scale retail channels / Private Label.",
        "We use he latest generation of Italian technology in pasta production.",
      ],
    },
  },
  {
    A: 2,
    B: "COOPERATIVA LOS MOLINOS LTDA.",
    C: "LOS MOLINOS",
    D: "SANTA FE",
    E: "(+54)03464-495144/116",
    F: "contacto@pastasmulini.com.ar / uriel.cisneros@pastasmulini.com.ar ",
    G: "www.pastasmulini.com.ar",
    H: "NO",
    I: "SI",
    J: "SI",
    location: {
      location: {
        lat: -33.1028061,
        lng: -61.3296598,
      },
      formated_address: "LOS MOLINOS, LOS MOLINOS, Argentina",
    },
    K: "NO",
    L: "SI",
    M: "SI",
    image: "/img/cooperativa-los-molinos.jpg",
    images: [
      "/img/losmolinos1.JPG",
      "/img/losmolinos2.JPG",
      "/img/losmolinos3.JPG",
      "/img/losmolinos4.jpg",
    ],
    products_images: [],
    capacity_class: "medium",
    es: {
      capacity: "Empresa de mediana capacidad",
      grain: "HACCP - ASC - Alimentos Argentinos",
      texts: [
        "Fideos de prensa cortos, de trigo duro, en varios formatos.",
        "Supermercados, distribuidores / Delikatessen",
        "Trabaja bajo los más altos estándares de calidad y trazabilidad desde la producción agropecuaria.",
      ],
    },
    en: {
      capacity: "Middle capacity company",
      grain: "HACCP - ASC - Alimentos Argentinos",
      texts: [
        "Noodles – short cut- variety shapes, made of Durum Wheat semolina. ",
        "Traditional trade , Retail channels / Delikatessen",
        "This company works under the highest standards of quality and traceability from agricultural production",
      ],
    },
  },
  {
    A: 3,
    B: "Establecimiento Fideero Berazategui SRL",
    C: "Platanos",
    D: "Buenos Aires",
    E: "4258-2343 / 4223-2920",
    F: "info@fideosquerico.com.ar / nadia@fideosquerico.com.ar ",
    G: "www.fideosquerico.com.ar",
    H: "SI",
    I: "SI",
    J: "SI",
    K: "SI",
    L: "SI",
    M: "NO",
    location: {
      location: {
        lat: -34.7777962,
        lng: -58.1762715,
      },
      formated_address: "Calle 39 A, Platanos, Buenos Aires, Argentina",
    },
    image: "/img/establecimiento-fideero-berazategui-srl.jpg",
    images: [
      "/img/berazategui1.JPG",
      "/img/berazategui2.JPG",
      "/img/berazategui3.JPG",
    ],
    products_images: [
      "/img/CORBATA_UNI.jpg",
      "/img/DOB_1_UNIDAD.jpg",
      "/img/DOB_2_UNIDAD.jpg",
      "/img/DOB_3_UNDAD.jpg",
      "/img/DOB_ESPINACA.jpg",
      "/img/DOB_ROJO UNI.jpg",
      "/img/MONOS_UNIDAD.jpg",
      "/img/NIDO_2.jpg",
      "/img/NIDO_3.jpg",
      "/img/NIDOS_1.jpg",
      "/img/PALITOS.jpg",
    ],
    capacity_class: "special",
    es: {
      capacity: "PRODUCTOS ESPECIALES",
      grain: "",
      texts: [
        "Pasta laminada: moños - tallarines - canelones - variedades especiales y dieteticos. CON Y SIN GLUTEN",
        "Supermercados, distribuidores / Delikatessen / Dietéticas",
        "Sin abandonar las pastas tradicionales y laminadas, este Establecimiento se ha especializado en productos altamente diferenciados como lasagñas y sopas instantaneas GLUTEN FREE entre otros.",
      ],
    },
    en: {
      capacity: "SPECIAL PRODUCTS",
      grain: "",
      texts: [
        "Laminated pasta: Farfalle - noodles - cannelloni - special varieties and dietetics. WITH AND WITHOUT GLUTEN",
        "Traditional trade , Retail channels / Delikatessen / Health Food Stores",
        "Without abandoning the production of traditional and laminated pasta, this company has also specialized in highly differentiated products such as lasagna and instant GLUTEN FREE soups, among others.",
      ],
    },
  },
  {
    A: 4,
    B: "Simon Hnos. SA",
    C: "La Banda",
    D: "Stgo. Del Estero",
    E: "(0385) 4275252 / 0052",
    F: "simonjaimer@gmail.com / info@tionico.com",
    G: "www.tionico.com",
    H: "SI",
    I: "SI",
    J: "NO",
    K: "NO",
    L: "NO",
    M: "NO",
    location: {
      location: {
        lat: -27.7406667,
        lng: -64.2257366,
      },
      formated_address:
        "Av. Cmte. Besares 2201, La Banda, Santiago del Estero, Argentina",
    },
    image: "/img/simon-hnos-sa.jpg",
    images: ["/img/DSC_6399.jpg"],
    products_images: [],
    capacity_class: "medium",
    es: {
      capacity: "Empresa de mediana capacidad",
      grain: "",
      texts: [
        "Fideos de prensa cortos y largos, de trigo duro y/o trigo pan, en varios formatos.",
        "Supermercados - Distribuidores ",
        "Con más de 80 años, Fideos Tio Nico es un  Establecimiento Fideero tradicional del Norte Argentino",
      ],
    },
    en: {
      capacity: "Middle capacity company",
      grain: "",
      texts: [
        "Noodles – short/long cut- variety shapes, made of Flour or Durum Wheat semolina. ",
        "Traditional trade - Retail channels.",
        "With more than 80 years, Tio Nico is a Traditional Pasta Company of the Argentine North Region.",
      ],
    },
  },
  {
    A: 5,
    B: "Marolio S.A",
    C: "Gral. Rodriguez",
    D: "Buenos Aires",
    E: "(+54)0237-4050750",
    F: "martin.cavallo@marolio.com / paola.ouvina@marolio.com",
    G: "www.marolio.com.ar",
    H: "NO",
    I: "SI",
    J: "NO",
    K: "NO",
    L: "SI",
    M: "SI",
    location: {
      location: {
        lat: -34.6179187,
        lng: -59.0156705,
      },
      formated_address: "Gral. Rodriguez, Buenos Aires",
    },
    image: "/img/Marolio-ai.jpg",
    images: [
      "/img/IMG_2664.jpg",
      "/img/IMG_2665.jpg",
      "/img/IMG_2704.jpg",
      "/img/IMG_2707.jpg",
      "/img/IMG_2723.jpg",
    ],
    products_images: [],
    capacity_class: "high",
    es: {
      capacity: "Empresa de gran capacidad",
      grain: "HACCP",
      texts: [
        "Fideos de prensa cortos, largos y soperos , de harina o semola de trigo duro, en varios formatos.",
        "Grandes Supermercados o distribuidores / Marcas Blancas",
        "Operamos tecnología italiana de última generación.",
      ],
    },
    en: {
      capacity: "High capacity company",
      grain: "HACCP",
      texts: [
        "Noodles – short, long, or soup- variety Shapes, made of flour or Durum Wheat semolina ",
        "Large-scale retail channels / Private Label.",
        "We use he latest generation of Italian technology in pasta production.",
      ],
    },
  },

  {
    A: 6,
    B: "Complejo Industrial Alimentary S.A",
    C: "Río Segundo",
    D: "Córdoba",
    E: "(+54)3572-403350",
    F: "ecupani@molinosriosegundo.com  / ciasa@ciasa.com.ar",
    G: "www.ciasa.com.ar",
    H: "NO",
    I: "SI",
    J: "NO",
    K: "NO",
    L: "SI",
    M: "SI",
    location: {
      location: {
        lat: -31.6486602,
        lng: -63.9221857,
      },
      formated_address: "Rio Segundo, Córdoba",
    },
    image: "/img/CIASA-0.jpg",
    images: [
      "/img/CIASA-1.jpg",
      "/img/CIASA-2.jpg",
      "/img/CIASA-3.jpg",
      "/img/CIASA-4.jpg",
    ],
    products_images: [],
    capacity_class: "high",
    es: {
      capacity: "Empresa de capacidad media",
      grain: "FSC 22.000",
      texts: [
        "Fideos de prensa cortos de trigo duro y/o trigo pan, en varios formatos.",
        "Supermercados - Distribuidores  - Marcas blancas",
        "Moderna fábrica de pastas y molino harinero ubicada en el corazon triguero de la provincia de Córdoba.",
      ],
    },
    en: {
      capacity: "MIDDLE CAPACITY COMPANY",
      grain: "FSC 22.000",
      texts: [
        "Noodles – short cut pasta- varied shapes, made of Flour or Durum Wheat semolina. ",
        "Traditional trade - Retail channels - Private Label.",
        "Modern pasta factory and flour mill located in the heart of the  wheat-producing region of the province of Córdoba.",
      ],
    },
  },

  {
    A: 7,
    B: "WAKAS Gluten Free",
    C: "Río Segundo",
    D: "Córdoba",
    E: "(+54)03327 - 444834",
    F: "contact@wakas.com  / rauljavierlucero@gmail.com ",
    G: "www.wakas.com.ar",
    H: "NO",
    I: "SI",
    J: "NO",
    K: "NO",
    L: "SI",
    M: "SI",
    location: {
      location: {
        lat: -31.6547563,
        lng: -63.9248679,
      },
      formated_address: "Rio Segundo, Córdoba",
    },
    image: "/img/Wakas.jpg",
    images: [],
    products_images: [],
    capacity_class: "high",
    es: {
      capacity: "PRODUCTOS ESPECIALES",
      grain: "",
      texts: [
        "Fideos de prensa cortos sin glúten en varios formatos y con distinto tipo de ingredientes.",
        "Supermercados, distribuidores / Delikatessen / Dietéticas",
        "Establecimiento especializado en productos de alto valor agregado, libres de glute, a base de cultivos ancestrales y legumbres.",
      ],
    },
    en: {
      capacity: "SPECIAL PRODUCTS",
      grain: "",
      texts: [
        "Noodles – short cut gluten free pasta- varied shapes and made with different types of ingredients.",
        "Traditional trade , Retail channels / Delikatessen / Health Food Stores",
        "Establishment specialized in high added value products , glute-free, based on ancient crops and legumes.",
      ],
    },
  },

  {
    A: 8,
    B: "Molinos Río de la Plata S.A",
    C: "Victoria",
    D: "Buenos Aires",
    E: "(+54)4746-8819",
    F: "agustin.torres.campbell@molinos.com.ar / santiago.oneto@molinos.com.ar ",
    G: "www.molinos.com.ar",
    H: "NO",
    I: "SI",
    J: "NO",
    K: "NO",
    L: "SI",
    M: "SI",
    location: {
      location: {
        lat: -34.4262959,
        lng: -58.5965262,
      },
      formated_address: "Rio Segundo, Córdoba",
    },
    image: "/img/molinos.jpg",
    images: [],
    products_images: [],
    capacity_class: "high",
    es: {
      capacity: "Empresa de gran capacidad",
      grain: "",
      texts: [
        "Fideos de prensa cortos, largos y soperos , de harina, semola de trigo duro u otras materias primas, en varios formatos. Variedades especiales",
        "Grandes Supermercados o distribuidores / Marcas Blancas",
        "Se trata del fabricante líder a nivel nacional. Posee tres plantas productivas, siendo capaz de ofrecer productos de escala, asi como también productos altamente diferenciados.",
      ],
    },
    en: {
      capacity: "HIGH CAPACITY COMPANY",
      grain: "",
      texts: [
        "Noodles – short / long cut pasta. Made of flour, Durum Wheat semolina or other raw materials. Special varieties.",
        "Large-scale retail channels / Private Label.",
        "It is the leading manufacturer nationwide. It has three production plants, being able to offer scale products, as well as highly differentiated products.",
      ],
    },
  },

  {
    A: 9,
    B: "COMPLEJO ALIMENTICIO SAN SALVADOR S.A.",
    C: "Colombres",
    D: "Tucum\u00e1n",
    E: "(+54)0381-4842800",
    F: "info@complejosansalvador.com.ar / claudiaromano@complejosansalvador.com.ar ",
    G: "www.complejosansalvador.com.a",
    H: "NO",
    I: "SI",
    J: "NO",
    K: "NO",
    L: "SI",
    M: "SI",
    location: {
      location: {
        lat: -26.892296,
        lng: -65.131502,
      },
      formated_address:
        "Ruta Provincial 302 KM.14, Finca Cruz del Norte - Cruz Alta, 4111 Colombres, Tucum\u00e1n, Argentina",
    },
    image: "/img/complejo-alimenticio-san-salvador-sa.jpg",
    images: [
      "/img/sansalvador1.jpg",
      "/img/sansalvador2.jpg",
      "/img/sansalvador3.jpg",
    ],
    products_images: [],
    capacity_class: "high",
    es: {
      capacity: "Empresa de gran capacidad",
      grain: "ISO 22005 - FSC 22000",
      texts: [
        "Fideos de prensa cortos, largos y soperos , de harina o semola de trigo duro, en varios formatos.",
        "Grandes Supermercados o distribuidores / Marcas Blancas",
        "Operamos tecnología italiana de última generación.",
      ],
    },
    en: {
      capacity: "High capacity company",
      grain: "ISO 22005 - FSC 22000",
      texts: [
        "Noodles – short, long, or soup- variety Shapes, made of flour or Durum Wheat semolina ",
        "Large-scale retail channels / Private Label.",
        "We use he latest generation of Italian technology in pasta production.",
      ],
    },
  },
];
